import { render, staticRenderFns } from "./PagingNavigator.vue?vue&type=template&id=4acc895e&scoped=true"
import script from "./PagingNavigator.vue?vue&type=script&lang=js"
export * from "./PagingNavigator.vue?vue&type=script&lang=js"
import style0 from "./PagingNavigator.vue?vue&type=style&index=0&id=4acc895e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4acc895e",
  null
  
)

export default component.exports