<template>
    <div id="paging-navigator" itemscope itemtype="https://schema.org/BreadcrumbList">
        <span v-for="(link, index) in links" :key="index" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
            <router-link
                v-if="isLink(link)"
                class="paging-navigator-link"
                :to="getLink(link)"
                itemscope
                itemtype="https://schema.org/WebPage"
                itemprop="item"
                :itemid="getLink(link)"
                ><span itemprop="name">{{ getName(link.name) }}</span></router-link
            >
            <span v-if="!isLink(link)" class="paging-navigator-link" itemscope itemprop="name" :itemid="getLink(link)">{{ getName(link.name) }}</span>
            <span v-if="index < links.length - 1">・</span>
            <meta itemprop="position" :content="index + 1" />
        </span>
    </div>
</template>

<script>
export default {
    name: "paging-navigator",
    props: ["links", "no-slashes"],
    methods: {
        isLink(link) {
            return typeof link.url === "string" ? link.url.replace(/\/$/, "") !== this.$route.path : true;
        },
        getLink(link) {
            return typeof link.url === "string" ? link.url.toLowerCase().replace(/\s/g, "-") : link.url;
        },
        getName(name) {
            if (this.noSlashes === true) {
                // Currently effecting mainly on the Blog routes.
                let returnVal = name.replace(/-/g, " ");
                return returnVal.length === 2 ? returnVal.toUpperCase() : returnVal.charAt(0).toUpperCase() + returnVal.slice(1);
            }
            return name;
        },
    },
};
</script>

<style lang="scss" scoped>
#paging-navigator {
    padding: 15px 0;
    margin-bottom: 20px;
    font-size: 14px;
    a.paging-navigator-link {
        color: #4a4a4a;
        text-decoration: underline !important;
    }
}
</style>
