<template>
    <div id="cards-area" :class="`objects-row`">
        <div itemtype="http://schema.org/ListItem" class="row justify-content-left" v-for="i in getCounter">
            <div v-for="object in getCardsForRow(i)" class="col-6" :class="getGridClass" :key="object.id">
                <category-card :search-engine-type="searchEngineType" :object="object" :store="store" :key="object.id"></category-card>
            </div>
        </div>
        <div v-if="!hideMessage && objectsToShow && objectsToShow.length === 0">
            <span>No results, please refine your search</span>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { DataLayerHelper } from "../../../mixins/DataLayerHelper";
import CategoryCard from "./CategoryCard.vue";

export default {
    name: "cards-area",
    props: ["objectsToShow", "store", "searchEngineType"],
    mixins: [DataLayerHelper],
    components: {
        "category-card": CategoryCard,
    },
    data() {
        return {
            compKey: 0,
            objType: null,
            hideMessage: true,
            GAalreadySent: false,
        };
    },
    watch: {
        objectsToShow: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                const baseCond = newValue && newValue.length > 0;
                // specify for fix duplicate send of GA product list - do not remove this line EVER.
                if (this.currentStore !== null && !this.currentStore.onlyLead && typeof oldValue === "undefined") return true;
                if (
                    (baseCond && this.$route.name !== "diamonds-page-diamond-popup" && this.$route.name !== "home-store" && !this.GAalreadySent) ||
                    (baseCond && this.$route.name === "home-store" && this.currentStore && this.currentStore.onlyLead === false && !this.GAalreadySent)
                ) {
                    this.sendGA({ impressions: newValue });
                }
                if (typeof oldValue !== undefined) this.hideMessage = false;
            },
        },
    },
    computed: {
        ...mapState("willyou", ["currentStore", "categoriesToShoInARow", "lastImpressions"]),
        getCounter() {
            return this.objectsToShow ? Math.ceil(this.objectsToShow.length / this.categoriesToShoInARow) : 1;
        },
        getGridClass() {
            return this.categoriesToShoInARow === 4 ? "col-lg-3" : "col-lg-4";
        },
        dl_filteredCurrentStore() {
            let routesWithoutStoreInfo = ["engagement-rings-search", "diamonds-page"];
            return this.currentStore && !routesWithoutStoreInfo.includes(this.$route.name) ? this.currentStore : null;
        },
    },
    methods: {
        ...mapActions("willyou", ["setLastImpressions"]),
        getCardsForRow(i) {
            return this.objectsToShow ? this.objectsToShow.slice((i - 1) * this.categoriesToShoInARow, i * this.categoriesToShoInARow) : null;
        },
        sendGA(payload) {
            let gaObj = {
                event: "product list",
                ecommerceLabel: this.dl_ecommerceLabel(),
                ecommerce: {},
            };

            // product list event
            const filteredImpressions = this.filterImpressions(payload.impressions);
            if (this.GAalreadySent) return false;

            this.GAalreadySent = true;

            gaObj.ecommerce.currencyCode = "USD";
            gaObj.ecommerce.impressions = filteredImpressions;
            this.setLastImpressions(filteredImpressions);

            window.dataLayer.push(gaObj);
        },
        filterImpressions(imps) {
            let pos = 0;
            if (this.objType === "diamonds") {
                return imps.map((imp) => {
                    pos++;
                    return {
                        name: imp.name,
                        id: imp.internal_lot,
                        price: imp.TotalPrice,
                        brand: this.dl_filteredCurrentStore ? this.dl_filteredCurrentStore.url : this.objType,
                        category: imp.Color,
                        variant: imp.Shape,
                        list: this.dl_ecommerceLabel(),
                        position: pos,
                    };
                });
            } else
                return imps.map((imp) => {
                    pos++;
                    return {
                        name: imp.name,
                        id: imp.internalID,
                        price: imp.TotalPrice,
                        brand: this.dl_filteredCurrentStore ? this.dl_filteredCurrentStore.url : this.objType,
                        category: imp.ringShape,
                        variant: imp.ringColor,
                        list: this.dl_ecommerceLabel(),
                        position: pos,
                    };
                });
        },
        dl_getPosition(clickedObj) {
            let locateImpressions = {};
            locateImpressions =
                this.objType === "rings"
                    ? this.lastImpressions.find((i) => i.id === clickedObj.internalID)
                    : this.lastImpressions.find((i) => i.id === clickedObj.internal_lot);
            return locateImpressions && locateImpressions.position ? locateImpressions.position : null;
        },
    },
};
</script>

<style lang="scss" scoped></style>
