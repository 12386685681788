<template>
    <div id="paging">
        <div class="row justify-content-center">
            <router-link :to="replacePageLink(currentPage - 1)" v-if="currentPage > 1" class="btn paging-btn rnd-secondary-btn previous-page">
                <img alt="->" src="../../assets/images/icons/left-arrow.svg" />
            </router-link>
            <router-link
                :to="replacePageLink(page)"
                v-for="(page, i) in newPages"
                :key="i"
                :aria-label="`to page number ${page}`"
                class="btn paging-btn"
                :class="{
                    'rnd-primary-btn': page == currentPage,
                    'rnd-secondary-btn': page != currentPage,
                    'first-btn': page === 1 && pages > 1,
                }"
                >{{ page }}</router-link
            >
            <router-link :to="replacePageLink(currentPage + 1)" v-if="currentPage < pages" class="btn paging-btn rnd-secondary-btn next-page">
                <img alt="->" src="../../assets/images/stores-search-results/arrow-right.svg" />
            </router-link>
        </div>
    </div>
</template>

<script>
import range from "lodash/range";

export default {
    name: "paging",
    props: ["pages"],
    data() {
        return { pageNeighbours: 5 };
    },
    computed: {
        currentPage() {
            return parseInt(this.$route.query.page ? this.$route.query.page : 1);
        },
        newPages() {
            return this.pages < 10
                ? range(1, this.pages + 1)
                : this.currentPage - this.pageNeighbours - 1 > 0 && this.currentPage + this.pageNeighbours <= this.pages
                ? range(this.currentPage - this.pageNeighbours, this.currentPage + this.pageNeighbours)
                : !(this.currentPage - this.pageNeighbours - 1 > 0)
                ? range(1, this.pageNeighbours * 2 + 1)
                : range(this.pages - this.pageNeighbours * 2 + 1, this.pages + 1);
        },
    },
    methods: {
        replacePageLink(page) {
            return {
                path: this.$route.path,
                query: Object.assign({}, this.$route.query, { page: page }),
            };
        },
    },
};
</script>

<style lang="scss" scoped>
#paging {
    padding: 50px;
    .paging-btn {
        width: 42px;
        padding: 6px;
        height: 42px;
        border-radius: 0;
        text-align: center;
        border: solid 1px #767d985e;
    }
    .next-page,
    .previous-page {
        padding: 7px;
        img {
            display: block;
        }
    }
    .next-page {
        border-left: none;
    }
    .previous-page {
        border-right: none;
    }
    .rnd-primary-btn {
        background-color: #fd8498;
    }
    .rnd-secondary-btn {
        color: #4a4a4a !important;
    }
    .first-btn {
        border-right: none;
    }
}
</style>
