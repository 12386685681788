<template>
    <div class="settings-diamonds-switcher">
        <router-link :to="`?searchenginetype=rings`">
            <button class="btn btn-filters btn-filters-lg first-btn btn-custom-style mobile-6" :class="{ 'rnd-primary-btn': !isDiamonds }">
                <span class="text-uppercase">Settings</span>
            </button>
        </router-link>
        <router-link :to="`?searchenginetype=diamonds`">
            <button class="btn btn-filters btn-filters-lg last-btn btn-custom-style mobile-6" :class="{ 'rnd-primary-btn': isDiamonds }">
                <span class="text-uppercase">Diamonds</span>
            </button>
        </router-link>
    </div>
</template>
<script>
export default {
    name: "settings-diamonds-switcher",
    props: ["status"],
    computed: {
        isDiamonds() {
            return this.status === "diamonds";
        },
    },
};
</script>

<style lang="scss" scoped>
.settings-diamonds-switcher {
    .btn-filters {
        height: 40px;
        border-radius: 4px;
        border: solid 1px #cacaca !important;
        background-color: #ffffff;
        color: #4a4a4a;
        font-size: 14px;
    }
    .btn-custom-style {
        padding: 0 22px;
    }
    .btn-filters:hover {
        border: solid 1px #fd8498;
    }
    .rnd-primary-btn {
        background-color: #fd8498;
    }

    @media (max-width: 767px) {
        .mobile-6 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            width: 50%;
        }
    }
}
</style>
