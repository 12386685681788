import { mapState } from "vuex";
// import { chunk } from "lodash";

export let DataLayerHelper = {
    mounted() {
        // debugger;
    },
    data() {
        return {
            objType: "",
        };
    },
    computed: {
        ...mapState("willyou", ["currentDiamond", "lastImpressions"]),
        dl_filteredCurrentStore() {
            let routesWithoutStoreInfo = ["engagement-rings-search", "diamonds-page"];
            return this.currentStore && !routesWithoutStoreInfo.includes(this.$route.name) ? this.currentStore : null;
        },
    },
    methods: {
        translateProduct(product, isDiamond) {
            return {
                name: product.name,
                id: isDiamond ? product.internal_lot : product.internalID,
                price: product.TotalPrice ? product.TotalPrice : null,
                category: isDiamond ? product.Color : product.ringColor,
                variant: isDiamond ? product.Shape : product.ringShape,
            };
        },
        sendGADetails(product) {
            const label = this.dl_ecommerceLabel();
            const isDiamond = this.objType === "diamonds";

            let gaObj = {
                event: "product details",
                ecommerceLabel: label,
                ecommerce: {
                    currencyCode: "USD",
                    detail: {
                        actionField: {
                            list: this.dl_ecommerceLabel(),
                            action: "detail",
                        },
                        products: [this.translateProduct(product, isDiamond)],
                    },
                },
            };

            gaObj.ecommerce.detail.products[0].brand = this.dl_filteredCurrentStore ? this.dl_filteredCurrentStore.url : this.objType;
            gaObj.ecommerce.detail.products[0].list = label;

            window.dataLayer.push(gaObj);
        },
        sendGAClick(product) {
            let gaObj = {
                event: "product click",
                ecommerceLabel: this.dl_ecommerceLabel(),
                ecommerce: {
                    currencyCode: "USD",
                },
            };

            // product click event
            gaObj.ecommerce.click = {
                actionField: {
                    list: this.dl_ecommerceLabel(),
                    action: "click",
                },
                products: [],
            };
            var item;
            if (this.objType === "rings") {
                item = this.lastImpressions.find((i) => i.id === product.internalID);
            } else {
                item = this.lastImpressions.find((i) => i.id === product.internal_lot);
            }
            if (!item === false) {
                Object.keys(item).forEach((key) => (key === "name" || key === "position" ? null : delete item[key]));
                gaObj.ecommerce.click.products[0] = { ...item };

                window.dataLayer.push(gaObj);
            }
        },
        dl_ecommerceLabel() {
            switch (this.$route.name) {
                case "stores-search-results-by-city":
                    return "jeweler city-stores";
                case "find-a-jeweler-by-city":
                    return "jeweler state-cities";
                case "find-a-jeweler-by-state":
                    return "jeweler store locator-state+city";
                case "live-showcase":
                    this.objType = "diamonds";
                    return "live diamond feed";
                case "diamonds-page-diamond-popup":
                    this.objType = "diamonds";
                    return "main diamond list";
                case "diamond-popup":
                    this.objType = "diamonds";
                    return "live diamond feed";
                case "product-diamond":
                    this.objType = "diamonds";
                    return "live diamond feed";
                case "engagement-rings-search":
                    this.objType = "rings";
                    return "main ring list";
                case "engagement-rings-search-product-ring":
                    this.objType = "rings";
                    return "main ring list";
                case "diamonds-page":
                    this.objType = "diamonds";
                    return "main diamond list";
                case "home-store":
                    if (this.searchEngineType === "diamonds") {
                        this.objType = "diamonds";
                        return "jeweler diamond list";
                    } else {
                        this.objType = "rings";
                        return "jeweler ring list";
                    }
                default:
                    return "";
            }
        },
        sendGAStoreLocatorList(list = null) {
            const label = this.dl_ecommerceLabel();
            let gaObj = {
                event: "product list",
                ecommerceLabel: label,
                ecommerce: {
                    currencyCode: "USD",
                    impressions: [...this.dl_getStoreLocatorImpressions(list)],
                },
            };
            window.dataLayer.push(gaObj);
        },
        dl_getStoreLocatorImpressions() {
            if (!this.objectsToShow && !this.storesAccurateToShow) return {};

            let pos = 0;
            let impressions = [];
            let currentRoute = this.$route.name;
            switch (currentRoute) {
                case "store-locator":
                    impressions = this.ga_impressions;
                    break;
                case "find-a-jeweler-by-city":
                    impressions = this.currentState.cities.map((ots) => {
                        pos++;
                        return {
                            name: ots.name,
                            list: this.dl_ecommerceLabel(),
                            position: pos,
                        };
                    });
                    break;
                case "stores-search-results-by-city":
                    impressions = this.storesAccurateToShow.map((ots) => {
                        pos++;
                        return {
                            name: ots.companyName,
                            list: this.dl_ecommerceLabel(),
                            position: pos,
                        };
                    });
                    break;
            }
            return impressions;
        },
    },
};
