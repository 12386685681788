<template>
    <div id="ring-filters">
        <div class="row">
            <div class="col-12">
                <filter-select
                    ref="filter-select"
                    :components="{ OpenIndicator }"
                    :options="['RING FILTERS']"
                    :closeOnSelect="false"
                    placeholder="RING FILTERS"
                    @selectOnTab="() => null"
                    :filterable="false"
                    :searchable="false"
                    :clearable="false">
                    <template slot="option" slot-scope="option">
                        <div class="filters-content-area">
                            <div class="form-group" v-if="setting && setting.length">
                                <label class="text-uppercase d-block" for="setting">setting</label>
                                <div class="btn-group flex-wrap" role="group" id="setting">
                                    <button
                                        v-for="(s, i) in setting"
                                        :key="i"
                                        class="btn btn-filters btn-filters-md"
                                        :class="{
                                            'rnd-primary-btn': isIncludes(currentSettings, s),
                                            'rnd-secondary-btn': !isIncludes(currentSettings, s),
                                            'first-btn': i === 0,
                                            'last-btn': i == setting.length - 1,
                                        }"
                                        @click="selectSetting(s)"
                                        :data-title="s">
                                        <img :alt="validUrl(s)" :src="`/images/category/rings-settings/${validUrl(s)}.svg`" />
                                    </button>
                                </div>
                            </div>
                            <div class="form-group" v-if="metalTypes && metalTypes.length">
                                <label class="text-uppercase d-block" for="metalTypes">gold type</label>
                                <div class="btn-group flex-wrap" role="group" id="metalTypes">
                                    <button
                                        v-for="(metalType, i) in metalTypes"
                                        :key="i"
                                        class="btn btn-filters btn-filters-lg"
                                        :class="{
                                            'rnd-primary-btn': isIncludes(currentTypes, metalType),
                                            'rnd-secondary-btn': !isIncludes(currentTypes, metalType),
                                            'first-btn': i === 0,
                                            'last-btn': i == metalTypes.length - 1,
                                        }"
                                        @click="selectType(metalType)">
                                        <span class="text-uppercase">{{ metalType }}</span>
                                    </button>
                                </div>
                            </div>
                            <div class="form-group" v-if="diamondShapes && diamondShapes.length">
                                <label class="text-uppercase d-block" for="diamondShapes">diamond shape</label>
                                <div class="btn-group flex-wrap" role="group" id="diamondShapes">
                                    <button
                                        v-for="(shape, i) in diamondShapes"
                                        :key="i"
                                        class="btn btn-filters btn-filters-sm"
                                        :class="{
                                            'rnd-primary-btn': isIncludes(currentShapes, shape),
                                            'rnd-secondary-btn': !isIncludes(currentShapes, shape),
                                            'first-btn': i === 0,
                                            'last-btn': i == diamondShapes.length - 1,
                                        }"
                                        @click="selectShape(shape)"
                                        :data-title="shape">
                                        <img :alt="validUrl(shape)" :src="`/images/category/diamonds-shapes/${getFileName(shape)}.svg`" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="submit-area w-100 pt-4 pb-4">
                                    <button aria-label="apply filters" class="float-right btn rnd-secondary-btn text-uppercase" @click="applyFilters">
                                        apply filters
                                    </button>
                                    <button
                                        aria-label="clear filters"
                                        class="float-right mr-2 btn rnd-secondary-btn text-uppercase no-border-button"
                                        @click="clearFilters">
                                        clear
                                    </button>
                                </div>
                            </div>
                        </div>
                    </template>
                </filter-select>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import FilterSelect from "../../../components/vue-select/src";
import uniq from "lodash/uniq";
import compact from "lodash/compact";
import { makeValidUrl } from "../../../helpers";

export default {
    name: "ring-filters",
    data() {
        return {
            OpenIndicator: {
                render: (createElement) =>
                    createElement("img", {
                        attrs: {
                            src: require("../../../../assets/images/icons/right.svg"),
                        },
                    }),
            },
            currentSettings: this.getStartingValue(this.$route.query.ringshapes),
            currentTypes: this.getStartingValue(this.$route.query.ringcolors),
            currentShapes: this.getStartingValue(this.$route.query.diamshapes),
        };
    },
    components: {
        "filter-select": FilterSelect,
    },
    computed: {
        ...mapState("willyou", ["rings"]),
        setting() {
            return this.getRingsFilterOptions.ringShapes.map((s) => makeValidUrl(s));
        },
        metalTypes() {
            return this.getRingsFilterOptions.ringColors.map((c) => makeValidUrl(c));
        },
        diamondShapes() {
            return this.getRingsFilterOptions.diamShapes;
        },
        getQuery() {
            let ringShapes = this.currentSettings.map((setting) => makeValidUrl(setting));
            let ringColors = this.currentTypes.map((types) => makeValidUrl(types));
            let diamShapes = this.currentShapes.map((shapes) => makeValidUrl(shapes));
            return { ringshapes: ringShapes, ringcolors: ringColors, diamshapes: diamShapes };
        },
        getCurrentRingsForLiveFilters() {
            const isFineByParamters = (filtersArr, value) => {
                return filtersArr && filtersArr.length ? filtersArr.includes(value) : true;
            };
            return this.rings && this.rings.length
                ? this.rings.filter((r) => {
                      return (
                          isFineByParamters(this.currentSettings, r.ringShape) &&
                          isFineByParamters(this.currentShapes, r.diamShape) &&
                          isFineByParamters(this.$route.query.ringColors, r.ringColor)
                      );
                  })
                : [];
        },
        getRingsFilterOptions() {
            return {
                ringShapes: uniq(this.rings.map((r) => r.ringShape)),
                diamShapes: uniq(this.rings.map((r) => r.diamShape)),
                ringColors: uniq(this.rings.map((r) => r.ringColor)),
            };
        },
    },
    methods: {
        getFileName(stoneName) {
            return stoneName.split(" ")[0];
        },
        selectSetting(setting) {
            this.currentSettings = this.isIncludes(this.currentSettings, setting)
                ? this.currentSettings.filter((s) => s !== setting && s !== setting.toLowerCase())
                : this.currentSettings.concat(setting);
        },
        selectType(type) {
            this.currentTypes =
                this.isIncludes(this.currentTypes, type.toLowerCase()) || this.isIncludes(this.currentTypes, type)
                    ? this.currentTypes.filter((t) => t !== type && t !== type.toLowerCase())
                    : this.currentTypes.concat(type);
        },
        selectShape(shape) {
            shape = makeValidUrl(shape);
            this.currentShapes = this.isIncludes(this.currentShapes, shape)
                ? this.currentShapes.filter((s) => s !== shape && s !== shape.toLowerCase())
                : this.currentShapes.concat(shape);
        },
        clearFilters() {
            this.currentSettings = [];
            this.currentTypes = [];
            this.currentShapes = [];
            this.applyFilters();
        },
        applyFilters() {
            this.$refs["filter-select"].open = false;
            const defaultObject = {
                page: 1,
                searchenginetype: "rings",
                sort: this.$route.query.sort,
                currentDiamond: this.$route.query.currentDiamond,
            };
            return this.$router.push({ query: Object.assign({}, this.getQuery, defaultObject) });
        },
        isIncludes(arr, value) {
            value = makeValidUrl(value);
            return arr && arr.length ? arr.includes(value.toLowerCase()) || arr.includes(value) : false;
        },
        getStartingValue(routeValue) {
            return Array.isArray(routeValue) ? routeValue : compact([routeValue]);
        },
        validUrl(value) {
            return makeValidUrl(value);
        },
    },
};
</script>

<style lang="scss" scoped>
#ring-filters {
    .filters-content-area {
        border-bottom: 1px solid #cacaca;
    }

    .btn-filters {
        border: solid 1px #cacaca;
        border-radius: 4px;
        margin-top: -1px;
        height: 40px;

        span {
            color: #4a4a4a;
        }
    }
    .btn-filters-sm {
        width: 60px;
        max-width: 60px;
    }

    .btn-filters-md {
        width: 80px;
        max-width: 80px;
    }

    .btn-filters-lg {
        width: 120px;
        max-width: 120px;
    }
    .rnd-secondary-btn {
        padding-right: 6px;
        padding-left: 6px;
    }
    .rnd-primary-btn {
        background-color: #fd8498;
        border: solid 1px #fd8498;

        span {
            color: #fff;
        }
    }
    .first-btn {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .no-border-button {
        border: none !important;
    }

    .last-btn {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    [data-title]:hover::before {
        content: attr(data-title);
        position: absolute;
        top: -20px;
        display: inline-block;
        padding: 3px 6px;
        border-radius: 2px;
        background: #1c2132;
        color: #fff;
        font-size: 12px;
        font-family: sans-serif;
        white-space: nowrap;
    }
    [data-title]:hover::after {
        content: "";
        position: absolute;
        top: 0px;
        right: -10px;
        display: inline-block;
        color: #fff;
        border: 8px solid transparent;
    }
}
</style>
