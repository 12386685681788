<template>
    <div id="diamond-filters">
        <div class="row">
            <div class="col-12">
                <filter-select
                    ref="filter-select"
                    :components="{ OpenIndicator }"
                    :options="['DIAMOND FILTERS']"
                    :closeOnSelect="false"
                    placeholder="DIAMOND FILTERS"
                    :filterable="false"
                    :searchable="false"
                    :clearable="false">
                    <template slot="option" slot-scope="option">
                        <div class="filters-content-area">
                            <div class="row">
                                <div class="col-12 col-lg-7">
                                    <div class="form-group">
                                        <label class="text-uppercase d-block" for="shapes">shape</label>
                                        <div class="btn-group flex-wrap" role="group" id="diamondShapes">
                                            <button
                                                v-for="(shape, i) in shapes"
                                                :key="i"
                                                class="btn btn-filters btn-filters-sm tiny-filter-btn p-0"
                                                :class="{
                                                    'rnd-primary-btn': isIncludes(currentShapes, shape),
                                                    'rnd-secondary-btn': !isIncludes(currentShapes, shape),
                                                    'first-btn': i === 0,
                                                    'last-btn': i == shapes.length - 1,
                                                }"
                                                @click="selectShape(shape)"
                                                :data-title="shape ? shape.charAt(0).toUpperCase() + shape.slice(1) : ''">
                                                <img
                                                    alt=">"
                                                    :src="`/images/category/diamonds-shapes/${
                                                        shape ? shape.charAt(0).toUpperCase() + shape.slice(1) : ''
                                                    }.svg`" />
                                            </button>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-uppercase d-block" for="clarities">clarity</label>
                                        <div class="btn-group flex-wrap" role="group" id="clarities">
                                            <button
                                                v-for="(clarity, i) in clarities"
                                                :key="i"
                                                class="btn btn-filters btn-filters-sm p-0"
                                                :class="{
                                                    'rnd-primary-btn': isIncludes(currentClarity, clarity),
                                                    'rnd-secondary-btn': !isIncludes(currentClarity, clarity),
                                                    'first-btn': i === 0,
                                                    'last-btn': i == clarities.length - 1,
                                                }"
                                                @click="selectClarity(clarity)">
                                                <span class="text-uppercase">{{ clarity }}</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-uppercase d-block" for="colors">color</label>
                                        <div class="btn-group flex-wrap" role="group" id="shapes">
                                            <button
                                                v-for="(color, i) in colors"
                                                :key="i"
                                                class="btn btn-filters btn-filters-sm tiny-filter-btn p-0"
                                                :class="{
                                                    'rnd-primary-btn': isIncludes(currentColors, color),
                                                    'rnd-secondary-btn': !isIncludes(currentColors, color),
                                                    'first-btn': i === 0,
                                                    'last-btn': i == colors.length - 1,
                                                }"
                                                @click="selectColor(color)">
                                                <span class="text-uppercase">{{ color }}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-5">
                                    <div class="form-group">
                                        <label class="text-uppercase d-block" for="Carat">carat</label>
                                        <input
                                            v-model="currentmincarat"
                                            type="number"
                                            class="form-control rnd-input"
                                            placeholder="0.70ct"
                                            min="0"
                                            max="5"
                                            step="0.1"
                                            @change="checkCaratInput('min')" />
                                        <span class="exp-text">to</span>
                                        <input
                                            v-model="currentmaxcarat"
                                            type="number"
                                            class="form-control rnd-input"
                                            placeholder="5.00ct"
                                            min="0"
                                            max="5"
                                            step="0.1"
                                            @change="checkCaratInput('max')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="submit-area w-100 pt-4 pb-4">
                                    <button aria-label="apply filters" class="float-right btn rnd-secondary-btn text-uppercase" @click="applyFilters">
                                        apply filters
                                    </button>
                                    <button
                                        aria-label="clear filters"
                                        class="float-right mr-2 btn rnd-secondary-btn text-uppercase no-border-button"
                                        @click="clearFilters">
                                        clear
                                    </button>
                                </div>
                            </div>
                        </div>
                    </template>
                </filter-select>
            </div>
        </div>
    </div>
</template>

<script>
import FilterSelect from "../../../components/vue-select/src";

export default {
    name: "diamond-filters",
    data() {
        return {
            OpenIndicator: {
                render: (createElement) =>
                    createElement("img", {
                        attrs: {
                            src: require("../../../../assets/images/icons/right.svg"),
                        },
                    }),
            },
            currentShapes: this.$route.query.shapes || [],
            currentClarity: this.$route.query.clarity || [],
            currentColors: this.$route.query.colors || [],
            currentCuts: this.$route.query.cuts || [],
            currentminbudget: this.$route.query.minbudget,
            currentmaxbudget: this.$route.query.maxbudget,
            currentmincarat: this.$route.query.mincarat,
            currentmaxcarat: this.$route.query.maxcarat,
            filtersOptions: {
                shape: ["oval", "emerald", "princess", "round", "pear", "cushion", "marquise", "radiant"],
                clarity: ["VVS2", "VS1", "VS2", "SI1", "SI2", "I1"],
                color: ["D", "E", "F", "G", "H", "I", "J"],
                cut: ["EX", "VG", "G", "F"],
            },
        };
    },
    components: {
        "filter-select": FilterSelect,
    },
    watch: {
        $route() {
            return this.updateDataFromUrl();
        },
    },
    computed: {
        shapes() {
            return this.filtersOptions.shape;
        },
        clarities() {
            return this.filtersOptions.clarity;
        },
        colors() {
            return this.filtersOptions.color;
        },
        cuts() {
            return this.filtersOptions.cut;
        },
        getQuery() {
            return {
                shapes: this.currentShapes,
                clarity: this.currentClarity,
                colors: this.currentColors,
                cuts: this.currentCuts,
                minbudget: this.currentminbudget,
                maxbudget: this.currentmaxbudget,
                mincarat: this.currentmincarat,
                maxcarat: this.currentmaxcarat,
            };
        },
    },
    methods: {
        selectShape(shape) {
            this.currentShapes = this.isIncludes(this.currentShapes, shape) ? this.currentShapes.filter((s) => s !== shape) : this.currentShapes.concat(shape);
        },
        selectClarity(clarity) {
            this.currentClarity = this.isIncludes(this.currentClarity, clarity)
                ? this.currentClarity.filter((s) => s !== clarity.toLowerCase())
                : this.currentClarity.concat(clarity.toLowerCase());
        },
        selectColor(color) {
            this.currentColors = this.isIncludes(this.currentColors, color)
                ? this.currentColors.filter((s) => s !== color.toLowerCase())
                : this.currentColors.concat(color.toLowerCase());
        },
        selectCut(cut) {
            this.currentCuts = this.isIncludes(this.currentCuts, cut) ? this.currentCuts.filter((s) => s !== cut) : this.currentCuts.concat(cut);
        },
        applyFilters() {
            this.$refs["filter-select"].open = false;
            const defaultObject = {
                page: 1,
                searchenginetype: "diamonds",
                sort: this.$route.query.sort,
                currentDiamond: this.$route.query.currentDiamond,
            };
            return this.$router.push({
                query: Object.assign({}, this.getQuery, defaultObject),
            });
        },
        clearFilters() {
            this.$refs["filter-select"].open = false;
            return this.$router.push({
                query: {},
            });
        },
        isIncludes(arr, value) {
            return arr && arr.length ? arr.includes(value.toLowerCase()) : false;
        },
        updateDataFromUrl() {
            this.currentShapes = this.$route.query.shapes ? this.returnArray(this.$route.query.shapes) : [];
            this.currentClarity = this.$route.query.clarity ? this.returnArray(this.$route.query.clarity) : [];
            this.currentColors = this.$route.query.colors ? this.returnArray(this.$route.query.colors) : [];
            this.currentCuts = this.$route.query.cuts ? this.returnArray(this.$route.query.cuts) : [];
        },
        returnArray(value) {
            return Array.isArray(value) ? value : [value];
        },
        checkCaratInput(type) {
            let newVal = event.target.value;

            if (parseFloat(event.target.value) > 5) newVal = 5;

            if (parseFloat(event.target.value) < 0.7) newVal = 0.7;

            if (type === "min") this.currentmincarat = newVal;
            if (type === "max") this.currentmaxcarat = newVal;
        },
    },
    mounted() {
        this.updateDataFromUrl();
    },
};
</script>

<style lang="scss" scoped>
#diamond-filters {
    .filters-content-area {
        border-bottom: 1px solid #cacaca;
    }

    .rnd-input {
        border: solid 1px #cacaca;
        height: 40px;
    }

    input::placeholder,
    .rnd-input:-moz-placeholder,
    .rnd-input::-webkit-input-placeholder,
    .rnd-input:-ms-input-placeholder {
        color: #fff !important;
    }

    .rnd-input:focus,
    .rnd-input:focus:hover {
        border: solid 1px #fd8498;
        box-shadow: none;
    }

    .exp-text {
        padding-left: 8px;
        padding-right: 8px;
    }

    .btn-filters {
        border: solid 1px #cacaca;
        border-radius: 4px;
        margin-top: -1px;
        height: 40px;

        span {
            color: #4a4a4a;
        }
    }

    .btn-filters-sm {
        width: 50px;
        max-width: 50px;
    }

    .btn-filters-md {
        width: 80px;
        max-width: 80px;
    }

    .btn-filters-lg {
        width: 120px;
        max-width: 120px;
    }

    .rnd-primary-btn {
        background-color: #fd8498;
        border: solid 1px #fd8498;

        span {
            color: #fff;
        }
    }

    .first-btn {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .last-btn {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .tiny-filter-btn {
        max-width: 40px;
    }

    .no-border-button {
        border: none !important;
    }

    @media (min-width: 1201px) {
        .rnd-input {
            display: inline;
            max-width: 90px;
        }
    }

    [data-title]:hover::before {
        content: attr(data-title);
        position: absolute;
        top: -20px;
        display: inline-block;
        padding: 3px 6px;
        border-radius: 2px;
        background: #1c2132;
        color: #fff;
        font-size: 12px;
        font-family: sans-serif;
        white-space: nowrap;
    }

    [data-title]:hover::after {
        content: "";
        position: absolute;
        top: 0px;
        right: -10px;
        display: inline-block;
        color: #fff;
        border: 8px solid transparent;
    }
}
</style>
