<template>
    <div :id="`category-card-${object.internalID}`">
        <div itemscope itemtype="http://schema.org/Product" class="card category-card">
            <a @click.prevent="addObjectToState(object, routerPath)" :href="$router.resolve(routerPath).href">
                <img itemprop="image" class="card-img-top" :alt="getAltText" v-lazy="imgMainSrc" />
            </a>

            <div class="card-body">
                <button v-if="!isStoreMode && !diamondsPage" aria-label="add to wishlist" class="btn btn-link float-right p-0" @click="addOrRemoveFromWishlist">
                    <svg
                        width="22px"
                        height="22px"
                        viewBox="0 0 22 22"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink">
                        <defs>
                            <path
                                d="M19.591974,6.36189838 C18.6823145,5.48377689 17.4733499,5 16.1872102,5 C14.9010705,5 13.6914348,5.48377689 12.7824464,6.36189838 L12.0009647,7.11656442 L11.2198185,6.36222241 C10.3104946,5.48410092 9.10119444,5.00032403 7.81505474,5.00032403 C6.52891503,5.00032403 5.31961487,5.48410092 4.41062649,6.36222241 C3.50130256,7.2403439 3,8.40814828 3,9.65015553 C3,10.8921628 3.50096702,12.0599672 4.41062649,12.9380887 L11.5261701,19.8101184 C11.6573678,19.9368141 11.8288307,20 12.0006291,20 C12.1724276,20 12.3438905,19.9368141 12.4750882,19.8101184 L19.591974,12.9380887 C21.469342,11.1248164 21.469342,8.17517066 19.591974,6.36189838 Z M18.6430559,12.0214076 L12.0006291,18.4352588 L5.3588735,12.0214076 C4.70288521,11.3879288 4.34150445,10.5457746 4.34150445,9.6498315 C4.34150445,8.75388836 4.70288521,7.91173421 5.3588735,7.27825542 C6.0148618,6.64477664 6.88694035,6.29612028 7.81438365,6.29612028 C8.74182694,6.29612028 9.61424104,6.64510067 10.2702293,7.27825542 L11.5258345,8.49109997 C11.7878943,8.74416746 12.2126929,8.74416746 12.4750882,8.49109997 L13.731029,7.27825542 C14.3870172,6.64477664 15.2594313,6.29579625 16.1868746,6.29579625 C17.1143179,6.29579625 17.986732,6.64477664 18.6427203,7.27825542 C19.9969754,8.58604078 19.9969754,10.7139463 18.6430559,12.0214076 Z"
                                id="path-1"></path>
                        </defs>
                        <g id="Symbols" stroke="none" stroke-width="1" :fill="isAddedToWishlist ? '#ff4967' : '#4a4a4a'" fill-rule="evenodd">
                            <g id="icon/Wishlist">
                                <mask id="mask-2" fill="white">
                                    <use xlink:href="#path-1"></use>
                                </mask>
                                <use id="Mask" xlink:href="#path-1"></use>
                            </g>
                        </g>
                    </svg>
                </button>
                <a @click.prevent="addObjectToState(object, routerPath)" :href="$router.resolve(routerPath).href">
                    <div v-if="object.name" class="card-title" itemprop="name">{{ object.name }}</div>
                    <div v-if="object.TotalPrice" itemprop="price" class="card-title">{{ useFromNumToPrice(object.TotalPrice) }}</div>
                </a>
                <div v-if="isDiamonds && object.Weight" class="total-caret-weight">Total carat weight: {{ object.Weight }}</div>
                <div v-if="!isDiamonds && object.ringColor" class="total-caret-weight">Ring color: {{ object.ringColor }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { createDiamondUrl, fromNumToPrice, makeValidUrl } from "../../../helpers";
import { DataLayerHelper } from "../../../mixins/DataLayerHelper";

export default {
    name: "category-card",
    props: ["object", "store", "searchEngineType"],
    mixins: [DataLayerHelper],
    computed: {
        ...mapState("willyou", ["wishlist", "storeMode", "currentStore", "iframeMode"]),
        diamondsPage() {
            return this.$route.name === "diamonds-page";
        },
        isDiamonds() {
            if (this.searchEngineType === "diamonds") return true;
            if (this.searchEngineType === "rings") return false;
            return this.$route.name === "diamonds" ||
                this.$route.name === "diamond-popup" ||
                this.$route.name === "live-showcase" ||
                this.$route.name === "diamonds-page"
                ? true
                : this.$route.query.searchenginetype === "diamonds"
                ? true
                : this.$route.query.searchenginetype
                ? false
                : true;
        },
        isStorePage() {
            return this.$route.name === "home-store";
        },
        isStoreMode() {
            return this.storeMode;
        },
        imgDefaultSrc() {
            let defaultSrc = this.isDiamonds ? "/images/category/example.png" : "/images/category/example2.png";
            if (this.isDiamonds && this.object && this.object.Shape !== "") {
                defaultSrc = `/images/category/diamonds-shapes/${this.object.Shape}.svg`;
            }
            return defaultSrc;
        },
        imgMainSrc() {
            return this.object.Media && this.object.Media.length && this.object.Media.find((m) => m.type === "thumbnail-sm")
                ? this.object.Media.find((m) => m.type === "thumbnail-sm").src
                : this.imgDefaultSrc;
        },
        isIframe() {
            return this.iframeMode === "true";
        },
        routerPath() {
            // Diamonds page
            if (this.diamondsPage) return { name: "diamonds-page-diamond-popup", params: { url: "diamonds", diamondUrl: createDiamondUrl(this.object) } };

            if (this.$route.name === "home-store" && this.currentStore && this.isDiamonds)
                return { name: "product-diamond", params: { url: this.currentStore.url, diamondUrl: createDiamondUrl(this.object) } };
            if (this.$route.name === "home-store" && this.currentStore && !this.isDiamonds)
                return { name: "product-ring", params: { url: this.currentStore.url, ringId: makeValidUrl(this.object.meta.url) } };

            if (this.isStoreMode) {
                return this.isDiamonds && this.store
                    ? { path: `/jewelers/${this.store}/live-diamond-feed/${createDiamondUrl(this.object)}` }
                    : { path: `/jewelers/${this.store}/product/ring/${this.object.name}` };
            }
            return this.isDiamonds && this.store ? { path: `/jewelers/${this.store}/product/diamond/${createDiamondUrl(this.object)}` } : this.createRingLink;
        },
        createRingLink() {
            if (this.store) {
                return { path: `/jewelers/${this.store}/product/ring/${makeValidUrl(this.object.meta.url)}` };
            } else {
                return { path: `/engagement-rings/${makeValidUrl(this.object.meta.url)}` };
            }
        },
        isAddedToWishlist() {
            if (this.diamondsPage) return null;

            return this.wishlist.find((product) => product.internalID === this.object.internalID);
        },
        getAltText() {
            return this.object && this.object.name ? this.object.name : null;
        },
    },
    methods: {
        ...mapActions("willyou", ["addDiamondToState", "addRingToState", "addProductToWishlist", "removeProductFromWishlist"]),
        addObjectToState(object, path) {
            this.addDiamondToState(null);
            this.isDiamonds ? this.addDiamondToState(object) : this.addRingToState(object);
            this.sendGAClick(object);

            this.$router.push(path);
        },
        addOrRemoveFromWishlist() {
            this.isAddedToWishlist ? this.removeProductFromWishlist(this.object.internalID) : this.addProductToWishlist(this.object);
            //   return this.isAddedToWishlist ? this.$swal.fire({
            //       position: 'top',
            //       width: 300,
            //       imageWidth: 50,
            //       type: 'success',
            //       icon: 'success',
            //       text: `${this.object.name} was successfully added to your wishlist`,
            //       showConfirmButton: false,
            //       timer: 2000,
            //   }) : null;
        },
        useFromNumToPrice(n) {
            return fromNumToPrice(n);
        },
    },
};
</script>

<style lang="scss" scoped>
.category-card {
    border: none;
    margin-bottom: 30px;
    p {
        margin: 0;
    }
    .card-body {
        padding: 15px 0;
    }
    .card-title {
        font-family: "Noto Sans KR", sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: #4a4a4a;
        margin: 0;
    }
    .total-caret-weight {
        font-family: "Noto Sans KR", sans-serif;
        font-size: 14px;
        font-weight: 300;
        color: #4a4a4a;
    }
}
</style>
