<template>
    <div id="filters-area">
        <div class="row">
            <div v-if="browserSwitcherStatus" class="col-12 mb-4 d-none settings-switcher-mobile-show">
                <settings-diamonds-switcher :status="getPageStatus"></settings-diamonds-switcher>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-7 mb-4">
                <div class="w-100">
                    <ring-filters v-if="!isDiamonds"></ring-filters>
                    <diamond-filters v-if="isDiamonds"></diamond-filters>
                </div>
            </div>
            <div class="col-12 col-md-5">
                <div>
                    <v-select
                        :components="{ OpenIndicator }"
                        :options="sortOptions"
                        :value="sort"
                        label="name"
                        placeholder="SORT"
                        :filterable="false"
                        :searchable="false"
                        :clearable="false"
                        class="float-right btn-sort text-capitalize"
                        @input="selectSort"></v-select>
                </div>
                <div v-if="browserSwitcherStatus" class="text-center float-right settings-switcher-mobile-hide mr-3">
                    <settings-diamonds-switcher :status="getPageStatus"></settings-diamonds-switcher>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RingFilters from "../rings/Filters.vue";
import DimaondFilters from "../diamonds/Filters.vue";
import SettingsDiamondsSwitcher from "./SettingsDiamondsSwitcher.vue";

export default {
    name: "filters-area",
    props: ["sort", "filterType", "browserSwitcher"],
    data() {
        return {
            OpenIndicator: {
                render: (createElement) =>
                    createElement("img", {
                        attrs: {
                            src: require("../../../../assets/images/icons/right.svg"),
                        },
                    }),
            },
            sortOptionsDiamonds: ["shape", "carat"],
            sortOptionsRings: ["style"],
        };
    },
    components: {
        "ring-filters": RingFilters,
        "diamond-filters": DimaondFilters,
        SettingsDiamondsSwitcher,
    },
    computed: {
        ...mapState("willyou", ["categoriesToShoInARow"]),
        browserSwitcherStatus() {
            return this.browserSwitcher !== "false";
        },
        getPageStatus() {
            return this.isDiamonds ? "diamonds" : "rings";
        },
        isDiamonds() {
            if (this.filterType === "diamonds") return true;
            if (this.filterType === "rings") return false;

            return this.$route.name === "diamonds" || this.$route.name === "live-showcase" || this.$route.name === "diamonds-page"
                ? true
                : typeof this.$route.query.searchenginetype !== "undefined" && this.$route.query.searchenginetype === "diamonds";
        },
        sortOptions() {
            return this.isDiamonds ? this.sortOptionsDiamonds : this.sortOptionsRings;
        },
    },
    methods: {
        ...mapActions("willyou", ["replaceCategoriesToShowInARow"]),
        selectSort(sort) {
            return this.$router.push({
                query: Object.assign({}, this.$route.query, { sort: sort }),
            });
        },
    },
};
</script>

<style lang="scss" scoped>
#filters-area {
    padding-bottom: 30px;

    .btn-filters {
        height: 40px;
        border-radius: 4px;
        border: solid 1px #cacaca;
        background-color: #ffffff;
        color: #4a4a4a;
        font-size: 14px;
    }

    .btn-filters:hover {
        border: solid 1px #fd8498;
    }

    .rotate {
        transform: rotate(90deg);
    }

    .icon-btn {
        width: 40px;
        padding: 0;
    }

    .rnd-primary-btn {
        background-color: #fd8498;
    }

    .rnd-secondary-btn {
        color: #4a4a4a !important;
    }

    .icon-right-btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .icon-left-btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    @media (min-width: 768px) {
        .btn-sort {
            width: 160px;
        }
    }

    @media (max-width: 1200px) {
        .settings-switcher-mobile-hide {
            display: none !important;
        }
        .settings-switcher-mobile-show {
            display: block !important;
        }
    }

    @media (max-width: 767px) {
        .btn-sort {
            width: 100%;
        }
    }
}
</style>
